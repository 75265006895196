<template>
  <div id="Blog">
    <top-control />
     <keep-alive>
        <router-view />
     </keep-alive>
  </div>
</template>

<script>
import TopControl from 'components/content/TopControl';
  export default {
    name: "Blog",
    components: {
      TopControl
    },
  };
</script>

<style lang="less" scoped>
  
</style>