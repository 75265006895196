import Alert from "./Alert";

const obj = {

}
// 当使用Vue.use时会自动调用install--mamba
obj.install = function (Vue) {
  // document.body.appendChild(Toast.$el)
  //1、创建组件构造器
  const alertConstructor = Vue.extend(Alert);

  //2、new的方式，根据组件构造器，可以创建出来一个组件对象
  const alert = new alertConstructor();
  //3、将组价对象，手动挂载到某一个元素上
  alert.$mount(document.createElement('div'));

  //4、toast.$el对应的就是div
  document.body.appendChild(alert.$el);

  Vue.prototype.$alert = alert;
}

export default obj