import {
  CURRENT_BLOGID,
  CURRENT_INDEX,
  BLOGIDARR,
  CURRENT_BLOGITEM
} from './mutation-types'

export default {
  //mutation唯一的目的，修改state中的状态
  //但是mutation方法规则是比较单一的，一个方法只做一件事情
  // 我们可以使用ES2015 风格的计算属性命名功能来使用一个常量作为函数名
  [BLOGIDARR](state, payload) {
    state.blogIdArr = payload;
  },
  [CURRENT_BLOGID](state, payload) {
    state.currentBlogId = payload;
  },
  [CURRENT_INDEX](state, payload) {
    state.index = payload;
  },
   [CURRENT_BLOGITEM](state, payload) {
    state.blogItem = payload;
  },
}


