<template>
  <div id="app">
    <div class="backGround">
        <router-view />
    </div>
  </div>
</template>

<script>
import Blog from "views/Blog";
export default {
  name: "app",
  components: {
    Blog,
  }
};
</script>

<style lang="less" scoped>
@import "./assets/style/css/Main.css";
.backGround {
  background: url("~@/assets/svg/bgcircle.svg") no-repeat top center;
  min-height: 100vh;
}
</style>

