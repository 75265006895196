import Vue from 'vue'
import VueRouter from 'vue-router'

// 这里采用懒加载吧
const Blog = () => import('../views/Blog')
const Personal = () => import('../views/PersonalInfo')
const Detail = () => import('../views/BlogDetail')
const BlogContent = () => import('../components/content/BlogContent')
const WriteBlog = () => import('../views/WriteBlog')

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    redirect: '/blog'
  },
  {
    path: '/blog',
    component: Blog,
    redirect: '/blog/blogContent',
    children: [
      {
        path: 'blogContent',
        component: BlogContent
      },
      {
        path: 'personal',
        component: Personal
      }
    ]
  },
  {
    path: '/detail/:id',
    component: Detail
  },
  {
    path: '/writeblog',
    name: 'WriteBlog',
    component: WriteBlog
  }
]

const router = new VueRouter({
  mode: 'history',
  linkActiveClass: 'active',
  routes
})

export default router
