import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'assets/style/css/Main.css'
import "github-markdown-css";
import alert from 'components/common/alert'
// 全局导入ElementUI
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

Vue.config.productionTip = false
// 使用Vue的原型来创建事件总线
Vue.prototype.$bus = new Vue()
// 安装alert插件
Vue.use(alert)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
