import {
  CURRENT_BLOGID,
  CURRENT_INDEX
} from './mutation-types';

export default {
  nextBlog(context) {
    return new Promise((resolve, reject) => {
      context.commit(CURRENT_INDEX, context.state.index + 1);
      if (context.state.index == context.state.blogIdArr.length) {
        context.commit(CURRENT_INDEX, context.state.index - 1);
        reject('已经到底了!')
      }
      context.commit(CURRENT_BLOGID, context.state.blogIdArr[context.state.index]);
      resolve(context.state.blogIdArr[context.state.index]);
    })

  },
  lastBlog(context) {
    return new Promise((resolve, reject) => {
      context.commit(CURRENT_INDEX, context.state.index - 1);
      if (context.state.index < 0) {
        context.commit(CURRENT_INDEX, 0);
        reject('已经到头了!')
      }
      context.commit(CURRENT_BLOGID, context.state.blogIdArr[context.state.index]);
      resolve(context.state.blogIdArr[context.state.index]);
    })

  },


}
