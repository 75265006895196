<template>
    <div id='Alert' v-show="isShow">
        <div class="alertBac">
            <div class="alert">
                <img :src="imgSrc(imgType)" alt="">
                <p class="message">{{message}}</p>
                <div class="line"></div>
                <button class="sure" @click="sureClick">确定</button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Alert',
        data() {
            return {
                isShow: false,
                message: '请确认您的操作哦!',
                imgType: ''
            }
        },
        computed: {
            imgSrc() {
                return (imgType) => {
                    if (imgType == 'warning') {
                        return require('assets/img/warning.png')
                    } else if (imgType == 'good') {
                        return require('assets/img/good.png')
                    }
                }
            }
        },
        methods: {
            show(message, imgType = 'warning') {
                this.imgType = imgType;
                if (message) {
                    this.message = message;
                }
                this.isShow = true;
            },
            sureClick() {
                this.hidden();
            },
            hidden() {
                this.isShow = false;
            }
        },
    }
</script>

<style scoped lang='less'>
    .alert {
        /* px-to-viewport-ignore-next */
        width: 300PX;
        /* px-to-viewport-ignore-next */
        height: 200PX;
        /* px-to-viewport-ignore-next */
        border-radius: 20PX;
        position: fixed;
        top: 50%;
        left: 50%;
        /* 这里的居中非常的巧妙，使用了变换--mamba*/
        transform: translate(-50%, -50%);
        padding: 8px 10px;
        z-index: 999;
        background-color: white;
        box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.3);

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }

    img {
        /* px-to-viewport-ignore-next */
        width: 50PX;
        /* px-to-viewport-ignore-next */
        height: 50PX;
    }

    .alertBac {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        z-index: 999;
        // background-color: rgba(0, 0, 0, 0.05);

    }

    .line {
        background-color: #F8F8F8;
        position: relative;
        top: 5px;
        height: 1px;
        width: 100%;
    }

    .sure {
        color: white;
        background-color: #3B64E0;
        /* px-to-viewport-ignore-next */
        padding: 10PX 50PX;
        border-radius: 5PX;
    }

    .message {
        padding-top: 10px;
        font-weight: 700;

    }
</style>