<template>
  <div id="top-control-item">
    <div class="title" @click="itemClick" :style="activeStyle">
      <div>{{ title }}</div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "TopControlItem",
    data() {
      return {};
    },
    props: {
      title: String,
      path: String,
      index:0,
      currentIndex:0,
    },
    computed: {
      isActive() {
        if (this.title == '博客' ||  this.title == '个人简介') {
          return this.$route.path.indexOf(this.path) != -1
        }else {
          return this.index === this.currentIndex
        }
      },
      activeStyle() {
        return this.isActive ? {
          opacity: 1
        } : {
          opacity: 0.5
        };
      },
    },
    methods: {
      itemClick() {
        if (!this.isActive) {
          if (this.title == "博客") {
            this.$emit("rightContainerShowAndHidden",true)
            this.$router.replace("/blog/blogContent");
          } else if (this.title == "个人简介") {
            this.$emit("rightContainerShowAndHidden",false)
            this.$router.replace("/blog/personal");
          }
          this.$emit("itemClick", this.index);
        }
      },
    },
  };
</script>

<style lang='less' scoped>
  .title {
    color: #082431;
    display: flex;
    align-items: center;
  }
</style>